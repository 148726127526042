import React from "react";
import { graphql } from "gatsby";

import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";

const DefaultPageMDX = ({ data: { page } }) => {
  return (
    <Layout {...page.frontmatter}>
      <ContentMDX source={page.body} />
    </Layout>
  );
};

export default DefaultPageMDX;

export const pageQuery = graphql`
  query DefaultPageMDX($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
      }
    }
  }
`;
